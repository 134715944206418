export default {
  namespaced: true,
  state: {
    dragList: [],
    dragListStart: [],
    materialsToLink: [],
    operationsToLink: [],
    operationList: [],
    resourceList: [],
    resourceToLink: [],
    isModalManageTagsOpen: false,
    isModalDuplicateOpen: false,
    isModalLinkMaterialsOpen: false,
    isModalLinkOperationsOpen: false,
    isModalResourceOpen: false,
    isModalResourceInfoOpen: false,
    isModalLinkResourceOpen: false,
    isModalOptionsResourceOpen: false,
    isModalAddEditOpen: false,
    isModalAddEditOperationOpen: false,
    isModalDeleteOpen: false,
    selectedChecks: 0
  },
  mutations: {
    UPDATE_DRAG_LIST(state, val) {
      state.dragList = val;
    },
    UPDATE_DRAG_LIST_START(state, val) {
      state.dragListStart = val;
    },
    UPDATE_MATERIALS_TO_LINK_LIST(state, val) {
      state.materialsToLink = val;
    },
    UPDATE_OPERATIONS_TO_LINK_LIST(state, val) {
      state.operationsToLink = val;
    },
    UPDATE_OPERATIONS_LIST(state, val) {
      state.operationList = val;
    },
    UPDATE_RESOURCE_LIST(state, val) {
      state.resourceList = val;
    },
    UPDATE_RESOURCE_TO_LINK_LIST(state, val) {
      state.resourceToLink = val;
    },
    UPDATE_MODAL_MANAGE_TAGS_TOGGLE(state, val) {
      state.isModalManageTagsOpen = val;
    },
    UPDATE_MODAL_DUPLICATE_TOGGLE(state, val) {
      state.isModalDuplicateOpen = val;
    },
    UPDATE_MODAL_LINK_MATERIALS_TOGGLE(state, val) {
      state.isModalLinkMaterialsOpen = val;
    },
    UPDATE_MODAL_LINK_OPERATIONS_TOGGLE(state, val) {
      state.isModalLinkOperationsOpen = val;
    },
    UPDATE_MODAL_RESOURCE_TOGGLE(state, val) {
      state.isModalResourceOpen = val;
    },
    UPDATE_MODAL_RESOURCE_INFO_TOGGLE(state, val) {
      state.isModalResourceInfoOpen = val;
    },
    UPDATE_MODAL_LINK_RESOURCE_TOGGLE(state, val) {
      state.isModalLinkResourceOpen = val;
    },
    UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE(state, val) {
      state.isModalOptionsResourceOpen = val;
    },
    UPDATE_MODAL_ADD_EDIT_ORDER(state, val) {
      state.isModalAddEditOpen = val;
    },
    UPDATE_MODAL_ADD_EDIT_OPERATION(state, val) {
      state.isModalAddEditOperationOpen = val;
    },
    UPDATE_MODAL_DELETE(state, val) {
      state.isModalDeleteOpen = val;
    },
    UPDATE_SELECTED_CHECKS(state, val) {
      state.selectedChecks = val;
    }
  }
};
